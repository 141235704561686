<template>
  <div id="app">
    <header class="content header">
      <a href="" target="_blank" class="font-color-51 none-selection none-cursor medium-font download">
        即将上线
      </a>
    </header>
    <section class="content about">
      <div>
        <h2 class="none-cursor bold-font font-color-51 about-title">关于时光鸭</h2>
        <div class="line"></div>
        <article class="none-cursor  regular-font font-color-102 about-introduce">
          美白美颜+智能抠图+修改背景+精美衣服服务！宝宝证件照、学生证件照、工作证件照在家就能制作！<br/>
          证件照自己拍，一键拍照超简单。一款功能强大的证件照制作APP，提供背景处理、美颜调整编辑功能，让你的证件照真实而又美丽。
        </article>
      </div>
    </section>
    <section class="content service">
      <div class="service-title-wrapper">
        <h2 class="none-cursor font-color-51 bold-font service-title">服务流程</h2>
        <div class="line"></div>
        <h2 class="none-cursor font-color-102 service-title-en"> Service process</h2>
      </div>
      <div class="service-content-wrapper">
        <div class="service-banner-wrapper" >
          <img class="service-banner"
              srcset="./assets/service1.png,
                     ./assets/service1@2x.png 2x,
                     ./assets/service1@3x.png 3x"
               src="./assets/service1.png"
               alt="service1">
          <img class="service-banner"
              srcset="./assets/service2.png,
                     ./assets/service2@2x.png 2x,
                     ./assets/service2@3x.png 3x"
               src="./assets/service2.png"
               alt="service2">
          <img class="service-banner"
              srcset="./assets/service3.png,
                     ./assets/service3@2x.png 2x,
                     ./assets/service3@3x.png 3x"
               src="./assets/service3.png"
               alt="service3">
          <img class="service-banner"
              srcset="./assets/service4.png,
                     ./assets/service4@2x.png 2x,
                     ./assets/service4@3x.png 3x"
               src="./assets/service4.png"
               alt="service4">
        </div>
        <div class="service-step-wrapper">
          <template v-for="(item, index) in service" >
            <div class="none-cursor service-step-box" :key="item">
              <div class="font-color-51 step-number">0{{index+1}}</div>
              <div class="font-color-51 step-name">{{item}}</div>
            </div>
            <div v-if="index !== 3" class="dot-line" :key="index"></div>
          </template>
        </div>

      </div>
    </section>
    <section class="content contact">
      <div >
        <h2 class="none-cursor bold-font font-color-51 contact-title">联系我们</h2>
        <h2 class="none-cursor font-color-102 contact-title-en">Contact us</h2>
      </div>
      <div>
        <a  class="font-color-102 contact-way" href="mailto:bd@tbps.ltd">邮箱: bd@tbps.ltd</a>
      </div>
    </section>
    <footer class="content footer">
      <div class="font-color-102">
        <span class="footer-info">© Copyright - 成都派诺互动科技有限公司 </span>
        <a class="font-color-102 none-decoration link-text footer-info" target="_blank" href="https://beian.miit.gov.cn/" >蜀ICP备2021019608号 </a>
        <a class="font-color-102 none-decoration link-text footer-info" target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51019002004013" >蜀公网安备51019002004013号 </a>
      </div>
      <div class="footer-icon-group">
<!--        <a  href=""><img class="license-icon" src="./assets/webLicense.png" alt="web license"></a>-->
<!--        <a  href=""><img class="whjy-icon" src="./assets/webWhjy.png" alt="文化经营执照"></a>-->
      </div>
    </footer>
  </div>
</template>

<script>

export default {
  name: 'App',

  data: function (){
    return {
      service: [
        '首页选择尺寸',
        '编辑拍摄证明',
        '制作证件照',
        '导出证件照',
      ]
    }
  },

  methods: {
    onDownload() {

    }
  }
}

</script>

<style lang="scss">
@import "style/common.scss";
$min-width: 1200px;

.content {
  min-width: $min-width;

  @media screen and (min-width: 1201px){
    padding: 0 calc(50% - #{$min-width} / 2);
  }
  @media screen and (max-width: 1200px){
    padding: 0 30px;
  }
}


.header {
  height: 1080px;
  background-image: url("./assets/headerBg.jpg");
  @media screen and (min-width: 1201px){
    background-image: url("./assets/headerBg@3x.jpg");
  }
  @media screen and (min-width: 751px) and (max-width: 1200px){
    background-image: url("./assets/headerBg@2x.jpg");
  }
  @media screen and (max-width: 750px){
    background-image: url("./assets/headerBg.jpg");
  }

  background-origin: padding-box;
  background-position: center;
  background-size: cover;
  position: relative;

  .download {
    width: 280px;
    line-height: 80px;
    text-align: center;
    font-size: 28px;
    background: rgba(255, 204, 84, .85);
    position: absolute;
    left: 50%;
    transform: translateX(-214%);
    bottom: 27.2%;
    border-radius: 50px;
    cursor: pointer;
    text-decoration: none;
  }
}

.about {
  height: 642px;
  @include flex-center;

  .line {
    margin: 32px 0;
    width: 32px;
    height: 6px;
    border-radius: 3px;
    background-color: rgb(255, 121, 74);
  }

  .about-title {
    font-size: 40px;
    line-height: 40px;
  }

  .about-introduce {
    font-size: 22px;
    line-height: 44px;
  }
}

.service {
  @include flex-between-column;
  $item-width: 308px;
  height: 998px;
  padding: 100px calc(50% - 1249px / 2);
  background-image: linear-gradient(to right, rgba( 255, 121, 74, .12), white, rgba(255, 204, 84, .12));

  .service-title-wrapper {
    @include flex-between-column;
    height: 126px;
    .service-title {
      font-size: 40px;
      line-height: 1;
    }
    .line {
      width: 32px;
      height: 6px;
      border-radius: 3px;
      background-color: rgb(255, 121, 74);
    }
    .service-title-en {
      text-transform: uppercase;
      font-size: 16px;
      font-family: 'MicrosoftYaHei', sans-serif;
    }
  }

  .service-content-wrapper {
    width: 100%;
  }
  .service-banner-wrapper {
    @include flex-between;
    .service-banner {
      width: $item-width;
      height: 572px;
    }
  }
  .service-step-wrapper {
    @include flex-between;

    margin-top: 23px;
    padding: 0 24px;
    .service-step {
      @include flex-center;
    }
    .service-step-box {
      text-align: center;
      width: 260px;
      @include flex-center-column;
      .step-name {
        margin-top: 15px;
        font-size: 18px;
      }
      .step-number {
        font-size: 20px;
        height: 22px;
        width: 40px;
        font-family: "MicrosoftYaHei-Bold", sans-serif;
        background-size: 28px 10px;
        background-repeat: no-repeat;
        background-position: 0 10px;
        $tmp-color: rgba( 255, 204, 85, 0.5);
        background-image: linear-gradient($tmp-color, $tmp-color);
      }
    }
    .dot-line {
      transform: translateY(-11px);
      width: 27px;
      height: 3px;
      background-image: linear-gradient(to right, rgb(102,102,102), rgb(102,102,102) 3px, transparent 3px, transparent 8.5px);
      background-size: 8px;
      background-repeat: repeat-x;
    }
  }


}
.contact {
  height: 420px;
  @include flex-between;
  .contact-title {
    font-size: 28px;
    margin-bottom: 12px;
  }
  .contact-title-en {
    font-size: 16px;
    text-transform: uppercase;
  }
  .contact-way {
    font-size: 16px;
    line-height: 1;
    margin-bottom: 16px;
    text-decoration: none;
  }
}

.footer {
  @include flex-center;
  height: 200px;
  background: rgba(51,51,51, .04);
  .footer-info {
    font-size: 16px;
    line-height: 1;
    //&:nth-of-type(2n + 1) {
    //  margin-bottom: 20px;
    //}
  }
  .footer-icon-group {
    height: 52px;
    width: 95px;
    @include flex-between;
    .license-icon {
      width: 34px;
      height: 34px;
    }
    .whjy-icon {
      width: 38px;
      height: 38px;
    }
  }
}

</style>
